import React from "react";
import { SnackbarContext } from "../context/snackbar";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { errorMessage: "" };
  }

  static contextType?: React.Context<any> | undefined = SnackbarContext;

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log("Global Error happen!!!");
    console.log(error);
    console.log(errorInfo);
    //this.context.openSnackbar(error.message, "error");
    //throw error;
  }

  render() {
    if ((this.state as any).errorMessage) {
      // You can render any custom fallback UI
      return (
        <main style={{ margin: "10px" }}>
          <h2>
            We are sorry but unfortunatly something went wrong with the
            application...
          </h2>
          <h3>
            Please, contact the administrator and send them this information:
          </h3>
          <p>{(this.state as any).errorMessage}</p>
        </main>
      );
    }
    return <>{(this.props as any).children}</>;
  }
}

export default ErrorBoundary;
