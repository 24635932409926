import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Route, Switch, HashRouter as Router } from "react-router-dom";

import { COLORS } from "./config/colors";
import { Login } from "./components/Login";
import { Dashboard } from "./views/Dashboard";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Header } from "./components/Header";
import { TvScreenView } from "./views/TvScreenView";

import { AuthProvider } from "./context/auth";
import { LangProvider } from "./context/lang";
import { StoreProvider } from "./context/store";
import { DriversStoreProvider } from "./context/driversStore";

import { AccessibilityProvider } from "./context/accessibility";

import { APP_PREFIX_URL } from "./config/app";

import { TripsProvider } from "./context/trips";

import "./utils/latinise";
import { ViewSettingsProvider } from "./context/viewSettings";
import { SnackbarProvider } from "./context/snackbar";
import "./style.css";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <Router basename={APP_PREFIX_URL}>
      <ThemeProvider theme={feedbackTheme}>
        <LangProvider>
          <SnackbarProvider>
            <ErrorBoundary>
              <AccessibilityProvider>
                <DriversStoreProvider>
                  <StoreProvider>
                    <AuthProvider>
                      <Header />
                      <Switch>
                        <Route path="/login" component={Login} />

                        <ViewSettingsProvider>
                          <TripsProvider>
                            <Route path="/tvScreen" component={TvScreenView} />
                            <>
                              {/* {isParkingApp() ? (
                      <ProtectedRoute exact path="/" component={Lists} />
                    ) : ( */}

                              <ProtectedRoute
                                exact
                                path="/"
                                component={Dashboard}
                              />
                            </>
                          </TripsProvider>
                        </ViewSettingsProvider>
                      </Switch>
                    </AuthProvider>
                  </StoreProvider>
                </DriversStoreProvider>
              </AccessibilityProvider>
            </ErrorBoundary>
          </SnackbarProvider>
        </LangProvider>
      </ThemeProvider>
    </Router>
  );
}

const feedbackTheme = createMuiTheme({
  palette: {
    primary: { main: COLORS.GREEN },
    secondary: { main: COLORS.GREEN_BLUE },
    error: { main: COLORS.RED }
  }
});

export default App;
