import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import pdfHeader from "../../assets/img/logoCropped.png";
//import QRCode from "qrcode";

import Icon from "@material-ui/core/Icon";

import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { Loader } from "../Loader";
import { useLang } from "../../context/lang";
import { IBooking } from "navision-proxy-api/@types";
//import { getDeliveryDate } from "containers/BookingDetails";
import { Button } from "@material-ui/core";
import { IMovement } from "navision-proxy-api/@types/terminal";

const styles = StyleSheet.create({
  page: {
    // display: "flex",
    // flexDirection: "column",
    // backgroundColor: "white",
    // height: "300px",
    // flexWrap: "wrap"
    fontSize: 8,
    fontWeight: 300,
    padding: 13
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  h1: {
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 1
  },
  h2: {
    fontSize: 9,
    fontWeight: 700
  },
  h2black: {
    fontSize: 9,
    fontWeight: 900
  },
  h3: {
    fontSize: 8,
    fontWeight: 600,
    marginBottom: 5
  },
  smallText: {
    fontSize: 4
  },
  midText: {
    fontSize: 6
  },
  boldText: {
    fontWeight: 700
  },
  headerImage: {
    height: 30
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    marginBottom: 5
  },
  body: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    width: 184,
    height: 62
  },
  container: {
    marginTop: 5,
    marginBottom: 5
  },
  containerText: {
    width: "75%"
  },
  containerQr: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end"
  },
  qr: {
    width: 45,
    height: 45
  },
  marginTop: {
    marginTop: 5
  },
  marginBottom: {
    marginBottom: 10
  },
  main: {
    width: 184,
    height: 121
  }
});

// Create styles

export const DownloadLabelsPdf = ({
  movements
}: {
  movements: IMovement[];
}) => {
  const [isReady, setIsReady] = React.useState(false);
  const isClicked = React.useRef(false);
  const qrRefs = React.useRef<any>({});

  const handleDownload = async (e: any) => {
    // await Promise.all(
    //   movements.map(async (movement): Promise<void> => {
    //     const qr = await QRCode.toDataURL(
    //       `${process.env.REACT_APP_BASE_URL}track/${booking.trackingNumber}`,
    //       { errorCorrectionLevel: "L" }
    //     );

    //     qrRefs.current[booking.bookingId] = qr;
    //   })
    // );
    setIsReady(true);
  };

  const { t } = useLang();

  return isReady ? (
    <BlobProvider
      document={
        <LabelsPdfTemplate
          t={t}
          movements={movements}
          //bookingsQr={qrRefs.current}
        />
      }
      //   style={{ color: "inherit", textDecoration: "none", height: "24px" }}
    >
      {({ blob, url, loading, error }) => {
        const tempLink = document.createElement("a");
        tempLink.href = url || "";
        tempLink.setAttribute(
          "download",
          `labels_${new Date().toISOString()}.pdf`
        );

        const download = () => {
          if (url && !isClicked.current) {
            tempLink.click();
            isClicked.current = true;
          }
        };
        download();
        return (
          <Loader margin={5} loading={loading}>
            <Button
              onClick={e => {
                tempLink.click();
              }}
              color="primary"
              variant="outlined"
            >
              {t("downloadLabels")}
            </Button>
          </Loader>
        );
      }}
    </BlobProvider>
  ) : (
    <Button color="primary" variant="outlined" onClick={handleDownload}>
      {t("downloadLabels")}
    </Button>
  );
};

interface ILabelsPdfTemplateProps {
  t: (key: string) => string;
  movements: IMovement[];
  //bookingsQr: { [key: string]: string };
}

// Create Document Component
export const LabelsPdfTemplate = ({
  t,
  movements
}: //bookingsQr
ILabelsPdfTemplateProps) => (
  <Document>
    {movements
      .map(movement =>
        movement.Unit.map((unit, unitIndex) => {
          const labelCount = parseInt(
            (movement.LoadedQty[unitIndex] ||
              movement.Quantity[unitIndex] ||
              "0") as string
          );

          return [...Array(labelCount).keys()].map(
            (goodNumber, _, labelCountArray) => {
              const labelCount = labelCountArray.length;
              return (
                <Page size="A8" style={styles.page} orientation="landscape">
                  <View style={styles.main}>
                    <View style={styles.header}>
                      <View style={styles.headerContainer}>
                        <Text style={styles.smallText}>{"Shipment ID: "}</Text>
                        <Text style={styles.h2}>{movement.ShipmentNr}</Text>
                        {movement.ShipmentRemark && (
                          <Text
                            style={styles.smallText}
                          >{`Remark: ${movement.ShipmentRemark}`}</Text>
                        )}
                      </View>
                      <Image src={pdfHeader} style={styles.headerImage} />
                    </View>
                    <View>
                      <Text style={styles.smallText}>{"Receiver: "}</Text>
                      <Text style={styles.h2black}>
                        {movement.AddresseeName}
                      </Text>
                      {movement.AddresseeName !==
                        movement.RawMovement.UnloadAddressName && (
                        <>
                          <Text style={styles.smallText}>
                            {"Delivery at: "}
                          </Text>
                          <Text style={styles.midText}>
                            {movement.RawMovement.UnloadAddressName}
                          </Text>
                        </>
                      )}
                      {/* <Text style={styles.midText}>
                      {booking.deliveryStreet + ", " + booking.deliveryLocation}
                    </Text> */}
                      {/* <Text style={styles.midText}>
                      {booking.deliveryAddress2}
                    </Text> */}
                      <View style={styles.body}>
                        <View style={styles.containerText}>
                          <View style={styles.container}>
                            <View>
                              <Text
                                style={styles.smallText}
                              >{`Latest delivery Date:  `}</Text>
                              <Text style={styles.midText}>
                                {movement.RawMovement.EndDate?.[0]}
                              </Text>
                            </View>
                          </View>
                          <Text style={styles.smallText}>{"Sender: "}</Text>
                          <Text style={styles.h3}>
                            {`${movement.SenderName}`}
                          </Text>

                          <View style={styles.marginTop}>
                            <View style={styles.flexBox}>
                              <Text style={styles.h2}>
                                {`${unit?.replaceAll("&dot;", ".")} `}
                              </Text>
                              <Text style={styles.h2}>{` ${
                                goodNumber + 1
                              } of ${labelCount}`}</Text>
                            </View>
                          </View>
                        </View>
                        <View style={styles.containerQr}>
                          {movement.ActualGate && (
                            <View style={styles.marginBottom}>
                              <Text style={styles.h2}>{"Gate: "}</Text>
                              <Text style={styles.h1}>
                                {movement.ActualGate}
                              </Text>
                            </View>
                          )}
                          {/* <Image
                            style={styles.qr}
                            src={bookingsQr[booking.bookingId]}
                          /> */}
                        </View>
                      </View>
                    </View>
                  </View>
                </Page>
              );
            }
          );
        })
      )
      .flat()
      .flat()}
  </Document>
);
