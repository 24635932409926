import _ from "lodash";

export const generateTrip = (movements: any[]) => {
  const tripNumbers: string[] = [];
  const vehicleNumbers: string[] = [];
  const trailerNumbers: string[] = [];

  movements.forEach((m: any) => {
    const { PartialTripNr, Vehicle, Trailer } = m;

    tripNumbers.push(PartialTripNr);
    vehicleNumbers.push(Vehicle);
    trailerNumbers.push(Trailer);
  });

  return {
    Lines: movements,
    PartialTripNr: _.uniq(tripNumbers)
      .filter(v => v)
      .join(","),
    Vehicle: _.uniq(vehicleNumbers)
      .filter(v => v)
      .join(","),
    Trailer: _.uniq(trailerNumbers)
      .filter(v => v)
      .join(",")
  };
};

export const isObject = (variable: any): boolean => {
  return (
    typeof variable === "object" &&
    variable !== null &&
    !Array.isArray(variable)
  );
};
